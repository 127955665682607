<template>
    <div class="intro row justify-content-center text-secondary">
        <!-- 返回頂部 -->
        <div class="goTop">
            <img src="@/assets/images/btn_gotop.png" alt="" class="w-100">
        </div> 

        <div class="col-12 col-xl-10">
            <div class="intro__part0 row position-relative pt-md-4" data-aos="fade-up" data-aos-duration="1500">
                <div class="col-md-6 col-lg-7 col-xl-8">
                    <img src="@/assets/images/img_kv_head.png" alt="" class="intro__part0__logo d-none d-xl-block my-3">
                    <p class="pr-md-3">漫遊淡水的一小角落，總是會發現人生的一大驚喜！一路從關渡到淡海，<br>連空氣都散發著濃厚的人文感、古早味。2021年淡水的得天獨厚條件下，在水運、<br>鐵路、捷運、輕鐵的串聯整合下，全新的淡水吹起懷舊、時尚且文青的生活型態。<br>以往淡水旅遊給你的印象是什麼呢？</p>
                    <h2 class="text-danger">新淡水、新玩法、新生活、新體驗，看上哪？愛上哪？</h2>
                    <p>快招集你的街坊鄰里、同窗好友們，都來支持你的心目中最有價值的景點吧！</p>
                </div>
                <img class="position-absolute intro__part0__illu d-none d-md-block" src="@/assets/images/intro/img_intro_0.png" alt="">

            </div>

            <!-- Filter -->
            <div class="subNav mb-3 mb-md-4" ref="clickaway" data-aos="fade-up" data-aos-duration="1500">
                <ul class="flex-grow-1 flex-md-grow-0 list-unstyled text-white mb-0">
                    <li @click="activeFilter == 'F1' ? showFilter('') : showFilter('F1')">
                        <button class="btn btn-danger dropdown font-weight-bold text-left text-white" :class="(activeFilter == 'F1' ? 'dropdown--active' : '')">
                            <span>{{defaultItem}}</span>
                            <svg class="arrow" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
                            <polygon points="11.63 5.19 8.01 8.44 4.37 5.18 3.2 6.51 8.01 10.81 12.8 6.51 11.63 5.19"/>
                            </svg>
                        </button>
                    </li>
                    
                    <transition name="toggle">
                        <li v-if="activeFilter == 'F1'" :class="(activeFilter == 'F1' ? 'active' : '')" class="dropdown__item">    
                            <ul class="list-unstyled" @click="activeFilter == 'F1' ? showFilter('') : showFilter('F1')">
                                <li @click="scrollToEvent()">
                                    <router-link :to="{ name: 'intro', hash: '#part1' }">全民推薦大搜密</router-link>
                                </li>
                                <li @click="scrollToEvent()">
                                    <router-link :to="{ name: 'intro', hash: '#part2' }">絕景攝影邀請賽</router-link>
                                </li>
                                <li @click="scrollToEvent()">
                                    <router-link :to="{ name: 'intro', hash: '#part3' }">八景4.0全民票選抽大獎</router-link>
                                </li>
                                <!-- </li v-for="(item, index) in EnjoyListStreet" :key="index" @click="defaultItem = item;RD(index);">{{item}}</> -->
                            </ul>
                        </li>
                    </transition>
                </ul>
            </div> 

            <!-- part1 全民推薦大搜密 -->
            <div v-if="defaultItem == '全民推薦大搜密'" class="intro__part1 odd position-relative" data-aos="fade-up" data-aos-duration="1500">
                <!-- 背景 -->
                <img v-if="deviceMode != 'mobile'" src="@/assets/images/intro/bg_intro_1_1.png" class="intro__part1__train--top w-100" alt="全民推薦大搜密">
                <img v-else src="@/assets/images/intro/bg_intro_1_1_mb.png" class="intro__part1__train--top w-100" alt="全民推薦大搜密">
                <!-- 輕軌 -->
                <img src="@/assets/images/intro/bg_intro_train1.svg" alt="" class="position-absolute intro__train intro__train--h">
                <img src="@/assets/images/intro/bg_intro_train2.svg" alt="" class="position-absolute intro__train intro__train--v">
                <!-- 隧道 -->
                <img src="@/assets/images/intro/bg_intro_tunnel1.svg" alt="" class="position-absolute intro__tunnel1">
                <img src="@/assets/images/intro/bg_intro_tunnel2.svg" alt="" class="position-absolute intro__tunnel2">
                <!-- sec 1 -->
                <section class="row position-relative" data-aos="fade-up" data-aos-duration="1500">
                    <div class="col-11 col-md-9 offset-md-2 col-lg-8 offset-lg-1 intro__part1__sec1">
                        <div class="row">
                            <div class="col-10 col-md-9"> 
                                <img class="w-100 mt-4 mb-3" src="@/assets/images/intro/img_intro_1_0.png" alt="全民推薦大搜密">
                            </div>
                        </div>            
                        <p class="h5 mb-3 mb-mb-5 pr-2 pr-md-0 font-weight-normal">喜好淡水的看倌大大們，肯定有不少口袋景點。<br class="d-none d-md-block">現在就參加「全民推薦大搜密」活動，<br class="d-none d-md-block">110年3月31日前推薦，讓你的私房好景立馬紅遍全台！</p>
                        <h2 id="sceneIntro_1" class="mb-3">一、推薦辦法與流程：</h2>  
                        <ol class="big-circle">
                            <li class="pr-2 pr-md-0">
                                <p class="h4 mb-4">點選活動頁面主選單「全民推薦大搜密」選項。</p>
                                <div class="row">
                                    <div class="col-11 col-md-9 offset-md-1 col-md-7">
                                        <img class="w-100 mb-4" src="@/assets/images/intro/img_intro_1_1.gif" alt="全民推薦大搜密">                                    
                                    </div>
                                </div>
                            </li>
                            <li class="pr-2 pr-md-0">
                                <p class="h4 mb-4">點選「我要推薦新景點」，進入景點推薦頁面。</p>
                                <div class="row">
                                    <div class="col-md-11">
                                        
                                        <img class="w-100 mb-4" src="@/assets/images/intro/img_intro_1_2.gif" alt="全民推薦大搜密">                                    
                                    </div>
                                </div>
                            </li>
                            <li class="pr-2 pr-md-0">
                                <p class="h4 mb-3 mb-md-5">填寫推薦景點相關資訊，即可完成景點推薦。</p>
                            </li>
                            <li class="pr-2 pr-md-0">
                                <p class="h4 mb-0 mb-md-4">經由活動小組審查確認推薦資料無誤後，<br class="d-none d-md-block">呈現於網頁供全民瀏覽。</p>
                            </li>
                        </ol>
                        <img v-if="deviceMode == 'mobile'" class="px-4 w-100" src="@/assets/images/intro/img_intro_1_deco3_mb.png" alt="">
                    </div>
                    <!-- bg -->
                    <img v-if="deviceMode != 'mobile'" class="position-absolute intro__part1__sec1__bg" src="@/assets/images/intro/bg_intro_1_4.svg" alt="">
                    <img v-else class="position-absolute intro__part1__sec1__bg" src="@/assets/images/intro/bg_intro_1_4_mb.svg" alt="">                    
                

                    <!-- 裝飾img -->
                    <img class="position-absolute intro__part1__sec1__deco1 d-none d-lg-block" src="@/assets/images/intro/img_intro_1_deco1.png" alt="">
                    <img class="position-absolute intro__part1__sec1__deco2" src="@/assets/images/intro/img_intro_1_deco2.gif" alt="">
                    <img v-if="deviceMode != 'mobile'" class="position-absolute intro__part1__sec1__deco3" src="@/assets/images/intro/img_intro_1_deco3.gif" alt="">       
                    <img class="position-absolute intro__part1__sec1__deco4 d-none d-lg-block" src="@/assets/images/intro/img_intro_1_deco4.png" alt="">
                </section>
                <img class="position-absolute intro__part1__sec1__deco5 d-none d-lg-block" src="@/assets/images/intro/img_intro_1_deco5.png" alt="">

                <!-- sec 2 -->
                <section class="row position-relative" data-aos="fade-up" data-aos-duration="1500">
                    <div class="col-11 col-md-8 offset-md-2 col-lg-9 offset-lg-1 intro__part1__sec2">
                        <h2>二、參加資格：</h2>
                        <p class="mb-4">凡熱愛淡水、認同本活動之民眾，不限國籍、居住地、性別、年齡，均可參與推薦活動。</p>
                        <h2 id="sceneIntro_2">三、注意事項：</h2>
                        <ol class="pl-4">
                            <li class="pr-2 pr-md-0">景點推薦資料於送出後，將無法更改，請於送出前先確認您填寫的資料是否有誤。</li>
                            <li class="pr-2 pr-md-0">景點照片將作為參考使用，不限全彩、黑白、單色拍攝，或電腦、手繪等圖像處理。</li>
                            <li class="pr-2 pr-md-0">若您於推薦景點同時勾選：照片為本人拍攝擁有版權，並願意提供給本活動使用作為活動推廣使用。主辦及承辦單位擁有將文字及照片複製、修改、發表、出版、展覽、宣傳或運用於其他目的等用途之權利，不另致酬。</li>
                            <li class="pr-2 pr-md-0">請詳細填寫欄位，並提供正確且無誤的完整資料，以免影響您參與活動的權益，謝謝。</li>
                        </ol>
                        <div class="col-9 col-md-6 col-xl-4 mx-auto my-4">
                            <router-link to="/scene">
                                <span class="w-100 btn btn-danger h5 font-weight-bold">前往全民推薦景點</span>
                            </router-link>
                        </div>
                    </div>
                    <!-- bg -->
                    <img v-if="deviceMode != 'mobile'" class="position-absolute intro__part1__sec2__bg" src="@/assets/images/intro/bg_intro_1_5.svg" alt="">
                    <img v-else class="position-absolute intro__part1__sec2__bg" src="@/assets/images/intro/bg_intro_1_5_mb.svg" alt="">
                </section>
                <img v-if="deviceMode != 'mobile'" src="@/assets/images/intro/bg_intro_1_3.png" alt="" class="intro__part1__train--bottom w-100">
                <img v-else src="@/assets/images/intro/bg_intro_1_3_mb.png" alt="" class="intro__part1__train--bottom w-100">
            </div>

            <!-- part2 絕景攝影邀請賽 -->
            <div v-if="defaultItem == '絕景攝影邀請賽'" id="intro__part2" class="intro__part2 even position-relative mb-2 mb-md-5"  data-aos="fade-up" data-aos-duration="1500">
                <!-- 背景 -->
                <img v-if="deviceMode != 'mobile'" src="@/assets/images/intro/bg_intro_2_8.png" class="intro__part2__train--top w-100" alt="絕景攝影邀請賽">
                <img v-else src="@/assets/images/intro/bg_intro_2_8_m.png" alt="絕景攝影邀請賽" class="intro__part2__train--top w-100">
                <!-- 輕軌 -->
                <img src="@/assets/images/intro/bg_intro_train1.svg" alt="" class="position-absolute intro__train intro__train--h">
                <img src="@/assets/images/intro/bg_intro_train2.svg" alt="" class="position-absolute intro__train intro__train--v">                
                <!-- 隧道 -->
                <img src="@/assets/images/intro/bg_intro_tunnel1.svg" alt="" class="position-absolute intro__tunnel1">
                <img src="@/assets/images/intro/bg_intro_tunnel2.svg" alt="" class="position-absolute intro__tunnel2">

                <!-- sec 1 -->
                <section class="row position-relative">
                    <div class="col-11 offset-1 col-md-9 offset-md-2 col-lg-8 offset-lg-2 intro__part2__sec1">                        
                        <div class="row">
                            <div class="col-10 col-md-8">
                                <img class="w-100 mb-3" src="@/assets/images/intro/img_intro_2_1.png" alt="絕景攝影邀請賽">
                            </div>
                        </div>
                        <p class="h5 mb-3 mb-mb-5 pr-2 pr-md-0 font-weight-normal">帶上你的手機、相機，以及你時尚與青春的心。<br class="d-none d-md-block">前往淡水將絕美的景觀收在你的記憶中，秀在人們的眼前吧！Let's Go！</p>
                        <h2 id="">一、活動時間：</h2>
                        <p class="h4 mb-4">2021/04/21(三)至2021/06/30(三)</p>
                        <h2 id="">二、參賽資格：</h2>
                        <p class="mb-4 pr-lg-5">不需繳交報名費，不限國籍，凡年滿十八歲以上(即民國92年4月20日前出生)之攝影愛好者均可參加。另外，若未滿二十歲之參賽者，需有監護人或法定代理人簽名同意。每人不限投件張數，分為社會組與學生組，參賽者僅能擇其中一組報名，不得跨組。</p>
                        <h2 id="">三、攝影主題：</h2>
                        <p class="mb-4">2021年淡水八景4.0票選活動候選景點。 <br class="d-none d-lg-block">(請參照下面列表之景點，擇任一景點或多景點進行拍攝) </p>
                    </div>
                    <!-- bg -->
                    <img class="position-absolute intro__part2__sec1__bg" src="@/assets/images/intro/bg_intro_2_1.svg" alt="">                    
                </section>

                <!-- sec 2 -->
                <section class="row position-relative" data-aos="fade-up" data-aos-duration="1500">
                    <div class="col-11 offset-1 col-md-9 offset-md-2 col-lg-9 offset-lg-2 intro__part2__sec2">
                        <router-link  v-for="(item, index) in SceneList" :key="index" :to="{ name: 'scene', hash: '#'+item.Title }" target="_blank">
                            <span v-html="item.TitleAlias" class="btn btn-outline text-left mr-1 mb-1 mr-lg-2 mb-lg-1"></span>
                        </router-link>
                    </div>
                    <!-- bg -->
                    <img class="position-absolute intro__part2__sec2__bg" src="@/assets/images/intro/bg_intro_2_2.png" alt="">
                </section>
                
                <!-- sec 3 -->
                <section class="row position-relative" data-aos="fade-up" data-aos-duration="1500">
                    <div class="col-11 offset-1 col-md-9 offset-md-2 col-lg-8 offset-lg-2 intro__part2__sec3">
                        <h2 id="">四、作品規格與參賽規則：</h2>
                        <ol class="big-circle">
                            <li class="mb-4">使用媒材：任何拍攝照片之器材（數位相機、傳統相機、手機等）皆可。 
                            </li>
                            <li class="mb-4">參賽作品不拘彩色或黑白照片，應為數位格式，檔案大小為1000萬畫素以上，解析度3000*3600pixel以上之JPG或TIFF檔，以光碟、隨身碟等形式保存或以EMAIL（寄至<a href="mailto:tamsui2021@gmail.com">tamsui2021@gmail.com</a>）提供。並將作品沖洗成6*8或6*9吋之照片，於背面黏貼作品標籤（附件三），並檢附報名表（附件一）及同意書（附件二）。參賽作品需於110年6月30日前，一律以掛號郵寄至新北市永和區中山路一段26號B1（慈暉文教基金會-秘書室收），恕不受理親自送件。</li>
                            <li class="mb-4">作品需保留檔案之原始 EXIF 可交換圖像文件資料或傳統相機之底片原始掃描檔案。 
                            </li>
                            <li class="mb-4">作品不得有疊片、翻拍拷貝、電腦合成、人工加色及數位修改，並應備份燒錄成光碟與比賽照片一起繳交。主、承辦單位有權要求參賽者提出影像原始檔（RAW），以判定影像是否經過加工。主、承辦單位有權判定加工攝影作品所採用之數位後製是否符合規則。若參賽者未能於主辦單位提出要求後五天內提供相關資料，該作品將被取消參賽資格。
                            </li>
                            <li class="mb-4">不得抄襲或盜用他人作品。作品規格未符合參賽辦法，或經發現為抄襲盜用他人作品者，將予以取消參賽資格，並自負相關法律責任，與主、協辦單位無涉。經評選獲獎者，取消其得獎資格。</li>
                            <li class="mb-4">所有參賽作品一稿不得多投。作品不論是否獲獎，一律不予發還，由承辦單位留存。若因郵遞或不可抗力致生損失，主、承、協辦單位恕不負賠償責任。</li>
                            <li class="mb-4">附件一報名表：請確實填寫每格欄位，並以A4紙列印。<br>
                                <a href="files/附件一.pdf" target="_blank" class="btn btn-danger mt-2 mr-2">附件一 報名表(PDF)</a>
                                <a href="files/附件一.docx" target="_blank" class="btn btn-danger mt-2">附件一 報名表(WORD)</a>
                            </li>
                            <li class="mb-4">附件二同意書：請以A4紙列印後親筆簽名或蓋章，並繳交正本。<br>(同意書恕不接受影本或掃描檔；20歲以下需請法定代理人簽章。) <br>
                                <a href="files/附件二.pdf" target="_blank" class="btn btn-danger mt-2 mr-2">附件二 同意書(PDF)</a>
                                <a href="files/附件二.docx" target="_blank" class="btn btn-danger mt-2">附件二 同意書(WORD)</a>
                            </li>
                            <li class="mb-4">附件三作品標籤：請依自訂之作品順序浮貼於每張照片背面，以避免因未標示清楚造成無法辨識作品等問題。<br>
                                <a href="files/附件三.pdf" target="_blank" class="btn btn-danger mt-2 mr-2">附件三 作品標籤(PDF)</a>
                                <a href="files/附件三.docx" target="_blank" class="btn btn-danger mt-2">附件三 作品標籤(WORD)</a>
                            </li>
                        </ol>
                    </div>
                    <!-- bg -->
                    <img class="position-absolute intro__part2__sec3__bg" src="@/assets/images/intro/bg_intro_2_3.svg" alt="">
                </section>

                <!-- sec 4 -->
                <section class="row position-relative" data-aos="fade-up" data-aos-duration="1500">
                    <div class="col-11 offset-1 col-md-9 offset-md-2 col-lg-8 offset-lg-2 intro__part2__sec4">
                        <h2 id="">五、攝影及收件日期：</h2>
                        <p class="mb-4">限2020年3月以後之作品，即日起收件至<span class="text-danger">2021年6月30日(以郵戳為憑)。</span></p>
                        <h2 id="">六、郵寄地址：</h2>
                        <p class="mb-4">(234)新北市永和區中山路一段26號B1，慈暉文教基金會-秘書室收，並註明「淡水八景4.0攝影比賽」。</p>
                        <h2 id="">七、評審：</h2>
                        <p class="mb-0 pr-lg-5">7月12日公布得獎名單，並由主、承、協辦單位聘請攝影名家組成評審團，參賽者對評審結果不得異議。</p>
                        <div class="prize row justify-content-center">
                            <div class="col-3 col-md-2 col-lg-2 offset-xl-1">
                                <img class="w-100 mb-md-3" src="@/assets/images/intro/img_intro_2_deco1.png" alt="">
                            </div>
                            <div class="w-100 d-lg-none"></div>
                            <div class="col-11 col-lg-9 px-lg-0">
                                 <p class="h4">獎勵辦法：<br class="d-md-none">(各組金、銀、銅牌獎不得重複）</p>
                                <p class="h4">社會組：</p>
                                <ul class="list-unstyled">
                                    <li>金牌獎：1名，獎金貳萬元、獎狀乙紙</li>
                                    <li>銀牌獎：1名，獎金壹萬元、獎狀乙紙</li>
                                    <li>銅牌獎：1名，獎金伍仟元、獎狀乙紙</li>
                                    <li>佳作獎：20名，每一位獎金壹仟伍佰元、獎狀乙紙</li>
                                </ul>
                                <p class="h4">學生組：</p>
                                <ul class="list-unstyled">
                                    <li>金牌獎：1名，獎金壹萬元、獎狀乙紙</li>
                                    <li>銀牌獎：1名，獎金柒仟元、獎狀乙紙</li>
                                    <li>銅牌獎：1名，獎金參仟元、獎狀乙紙</li>
                                    <li>佳作獎：20名，每一位獎金壹仟元、獎狀乙紙</li>
                                </ul>
                                <p class="h4">頒獎典禮：</p>
                                <p class="mb-0">將於新北市滬尾藝文休閒園區「滬尾故事館」公開頒獎，<br class="d-none d-lg-block"><span class="font-weight-bold text-danger">頒獎典禮日期將與評選結果同步公告於活動官網。</span></p>
                            </div>                           
                        </div>
                        <h2 id="">八、著作權屬辦法：</h2>
                        <p class="mb-4">參賽者無論得獎與否均同意主、承辦單位使用，並刊登於網站與手冊，參賽者並同意不行使著作人格權。</p>
                        <h2 id="">九、其他附則：</h2>
                        <p class="mb-4">其他未訂事項，主、承辦單位得隨時修正補充解釋之。</p>
                        <div class="col-9 col-md-6 col-xl-4 mx-auto mb-4">
                            <router-link to="/outstanding">
                                <span class="w-100 btn btn-danger h5 font-weight-bold">欣賞參賽作品</span>
                            </router-link>
                        </div>
                    </div>
                    <!-- bg -->
                    <img class="position-absolute intro__part2__sec4__bg" src="@/assets/images/intro/bg_intro_2_4.svg" alt="">
                </section>

                <!-- 裝飾img -->
                <img v-if="deviceMode != 'mobile'" class="position-absolute intro__part2__deco2" src="@/assets/images/intro/img_intro_2_deco2.gif" alt="">
                <img v-else class="position-absolute intro__part2__deco2" src="@/assets/images/intro/img_intro_2_deco2_mb.png" alt="">
                <img class="position-absolute intro__part2__deco3 d-none d-lg-block" src="@/assets/images/intro/img_intro_2_deco3.gif" alt="">       
                <img class="position-absolute intro__part2__deco4" src="@/assets/images/intro/img_intro_2_deco4.gif" alt="">
                <img class="position-absolute intro__part2__deco5 d-none d-md-block" src="@/assets/images/intro/img_intro_2_deco5.png" alt="">
                <img class="position-absolute intro__part2__deco6" src="@/assets/images/intro/img_intro_2_deco6.png" alt="">
                <img class="position-absolute intro__part2__deco7 d-none d-lg-block" src="@/assets/images/intro/img_intro_2_deco7.png" alt="">
                <img class="position-absolute intro__part2__deco8 d-none d-lg-block" src="@/assets/images/intro/img_intro_2_deco8.gif" alt="">
                <img class="position-absolute intro__part2__deco9" src="@/assets/images/intro/img_intro_2_deco9.png" alt="">
                <img class="position-absolute intro__part2__deco10 d-none d-lg-block" src="@/assets/images/intro/img_intro_2_deco10.png" alt="">


                <img v-if="deviceMode != 'mobile'" src="@/assets/images/intro/bg_intro_2_6.png" alt="" class="intro__part2__train--bottom w-100">
                <img v-else src="@/assets/images/intro/bg_intro_2_6_mb.png" alt="" class="intro__part2__train--bottom w-100">
            </div>

            <!-- part3 八景4.0全民票選抽大獎 -->
            <div v-if="defaultItem == '八景4.0全民票選抽大獎'" class="intro__part3 odd position-relative mb-md-5" data-aos="fade-up" data-aos-duration="1500">
                <!-- 背景 -->
                <img v-if="deviceMode != 'mobile'" src="@/assets/images/intro/bg_intro_3_1.png" class="intro__part3__train--top w-100" alt="八景4.0全民票選抽大獎">
                <img v-else src="@/assets/images/intro/bg_intro_3_1_m.png" class="intro__part3__train--top w-100" alt="八景4.0全民票選抽大獎">
                <!-- 輕軌 -->
                <img src="@/assets/images/intro/bg_intro_train1.svg" alt="" class="position-absolute intro__train intro__train--h">
                <img src="@/assets/images/intro/bg_intro_train2.svg" alt="" class="position-absolute intro__train intro__train--v">
                <!-- 隧道 -->
                <img src="@/assets/images/intro/bg_intro_tunnel1.svg" alt="" class="position-absolute intro__tunnel1">
                <img src="@/assets/images/intro/bg_intro_tunnel2.svg" alt="" class="position-absolute intro__tunnel2">
                <!-- sec 1 -->
                <section class="row position-relative" data-aos="fade-up" data-aos-duration="1500">
                    <div class="col-11 col-md-9 offset-md-1 col-lg-9 offset-lg-1 intro__part3__sec1">
                        <div class="row">
                            <div class="col-11 col-md-10 offset-md-1 col-lg-9">
                                <img class="w-100 my-3" src="@/assets/images/intro/img_intro_3_1.png" alt="八景4.0全民票選抽大獎">      
                            </div>
                        </div>
                        <p class="h5 mb-3 mb-mb-5 pr-2 pr-md-0 font-weight-normal">邀請你的朋友來投票，建立最強鐵粉團！一起點亮心中最美的淡水秘境吧！</p>
                        <div class="row">
                            <div class="col-lg-9">
                                <h2>一、活動時間：</h2>
                                <p class="h4 mb-4">2021/08/05(四)至2021/09/30(四)</p>
                                <h2>二、投票資格：</h2>
                                <p class="mb-4 pr-lg-5">凡中華民國國籍，凡年滿十二歲以上(即民國99年8月5日前出生)之民眾均可參加。</p>
                            </div>
                            <div class="col-md-8">
                                <h2 class="mb-3">三、票選方式：</h2>
                                <span class="d-inline-block badge-pill badge-danger font-weight-bold py-1 mb-2">投票方式</span>
                                <p class="mb-3 mb-lg-5">票選活動期間由民眾於本活動網站 「淡水八景4.0全民票選抽大獎」活動中，為心目中喜愛的景點投票，每人可投1至8票，一個景點僅能投一票，不可重複。民眾參與前，必須先填妥各項正確個人資料(資料僅供本次活動使用)，方可參加景點投票及抽獎活動，本活動將於票選期間結束後抽出30名得獎者。</p>
                            </div>
                            <div class="col-md-4 d-flex align-items-center align-items-lg-end">
                                <div class="row justify-content-center">
                                    <img class="col-6 col-md-12 pr-xl-4" src="@/assets/images/intro/img_intro_3_3.png" alt="">  
                                </div>
                            </div>
                            <div class="col-md-5 order-12 order-md-1 d-flex align-items-center">
                                <div class="row justify-content-center">
                                    <img class="col-7 col-md-12 px-2" src="@/assets/images/intro/img_intro_3_4.png" alt=""> 
                                </div>
                            </div>
                            <div class="col-md-7 order-1 order-md-12 d-flex align-items-center">
                                <div>
                                    <span class="d-inline-block badge-pill badge-danger font-weight-bold py-1 mb-2">計票方式</span>
                                    <p>網路民眾投票成績占「淡水八景4.0全民票選」景點評選依據之50%，另主辦單位安排專業評審之評選亦占50%，故網路投票票數，非最終「淡水八景4.0全民票選」景點評選結果。</p>                                
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- bg -->
                    <img class="position-absolute intro__part3__sec1__bg" src="@/assets/images/intro/bg_intro_3_2.svg" alt=""> 

                    <!-- 裝飾img -->
                    <img class="position-absolute intro__part3__sec1__deco1 d-none d-lg-block" src="@/assets/images/intro/img_intro_3_deco1.png" alt="">
                </section>

                <!-- sec 2 -->
                <section id="part3__sec2" class="row position-relative" data-aos="fade-up" data-aos-duration="1500">
                    <div class="col-11 col-md-9 offset-md-1 col-lg-9 offset-lg-1 intro__part3__sec2">
                        <h2>四、活動獎項：</h2>
                        <p class="mb-0 mb-md-4">凡參與「淡水八景4.0全民票選抽大獎」活動者，都有機會抽中以下獎項。中獎名單，將於2021年10月8日抽獎後，公布於本活動網站及「慈暉文教基金會」粉絲團，請密切注意活動公告訊息；得獎者應於主辦單位通知之期限內，回覆確認同意領取獎品，逾期視為棄權。</p>
                        <div class="row justify-content-center">
                            <div class="col-md-10">
                                <img class="w-100 my-3" src="@/assets/images/intro/img_intro_3_5.png" alt="八景4.0全民票選抽大獎"> 
                            </div>
                            <div class="col-9 col-md-6 col-xl-4">
                                <router-link to="/vote">
                                    <span class="w-100 btn btn-danger h5 font-weight-bold">來去投票 <img style="width: 22px; vertical-align: sub;" src="@/assets/images/vote/btn_vote_deco2.png" alt=""></span>
                                </router-link>
                            </div>
                        </div>
                    </div>
                    <!-- bg -->
                    <img class="position-absolute intro__part3__sec2__bg" src="@/assets/images/intro/bg_intro_3_3.svg" alt="">
                </section>
                <!-- sec 3 -->
                <section id="part3__sec3" class="row position-relative" data-aos="fade-up" data-aos-duration="1500">
                    <div class="col-11 col-md-9 offset-md-1 col-lg-9 offset-lg-1 intro__part3__sec3">
                        <h2>五、抽獎注意事項：</h2>
                        <p class="mb-4">凡參與「淡水八景4.0全民票選抽大獎」活動者，都有機會抽中以下獎項。中獎名單，將於2021年10月8日抽獎後，公布於本活動網站及「慈暉文教基金會」粉絲團，請密切注意活動公告訊息；得獎者應於主辦單位通知之期限內，回覆確認同意領取獎品，逾期視為棄權。</p>
                        <ol class="big-circle">
                            <li class="mb-3">「淡水八景4.0全民票選抽大獎」活動，每人僅有一次投票機會，但可投1至8票，一個景點僅能投一票，不可重複。
                            </li>
                            <li class="mb-3">承辦單位將依「淡水八景4.0全民票選抽大獎」中獎者參加投票時所填之手機號碼聯繫中獎者，並請中獎者提供身分證明以供核對身分，故請投票者於投票時務必正確填寫資料，若填寫之資料不正確或不真實或與參加投票時提供之資料不符者，主辦單位得取消其中獎資格。
                            </li>
                            <li class="mb-3">主、承辦單位不接受中獎人變更中獎人姓名(以投票時填寫之姓名為準)，以避免冒領獎項之情況。</li>
                            <li class="mb-3">獎品配送地點僅限台灣、金門、澎湖、馬祖地區，所有獎項之聯絡以中獎人於投票時填寫之資料為準，參加者請務必確認資料無誤及填載正確。</li>
                            <li class="mb-3">「淡水八景4.0全民票選抽大獎」中獎者就所得獎項不得要求折抵現金、退換、轉售（讓）或折換其他物品。如遇產品缺貨或不可抗力之事由導致獎品內容變更，主辦單位有權變更贈品，改由等值商品取代之，得獎者不得要求折現或轉換其他商品。且主辦單位與承辦單位不負贈品之任何維護或保固責任。</li>
                            <li class="mb-3">本會非獎品製造者或提供者，且與各該獎品或服務之提供廠商無任何代理或合夥關係。得獎人如因本活動各項獎品或服務發生任何爭議，概與本會無涉。</li>
                            <li class="mb-3">本活動如有任何因電腦、網路、電話、技術或其他不可歸責於主、
                            承辦單位之事由，而使參與本活動者所登錄之資料有所遺失、錯誤、無法辨識或毀損所導致資料無效之狀況，主、承辦單位不負任何法律責任，參加者與中獎者亦不得異議。</li>
                            <li class="mb-3">主、承辦單位有權檢視各活動參加者之活動參與行為及中獎情形是否涉嫌，例如：人為操作、蓄意偽造、短時間異常多筆參與行為、透過任何電腦程式參與活動、詐欺或以任何其他不正當的方式進行不實或虛偽活動參與行為，活動參加者或中獎人因上述情形所獲得之活動資格及獎品，主辦單位保有取消參加抽獎或得獎資格的權利，並保留法律追訴權。</li>
                            <li class="mb-3">參加者於參加本活動同時，即表示已充分知悉與同意以下事項：本會得因本活動之需要，依個人資料保護法相關規定，於本活動參加者同意之期間內，以電子檔或紙本形式於我國境內蒐集、處理、利用其個人資料。但本會於未經本活動參加者之同意下，不得利用本活動參加者之個人資料進行商業行銷行為。本活動參加者可自由選擇是否提供完整個人資訊，惟若資訊不完整者，將無法參加本活動。</li>
                            <li class="mb-3">抽獎日期及得獎公告日期如遇不可抗力因素致無法抽獎或公告時，本會將於該不可抗力因素消滅後辦理抽獎或公告。</li>
                            <li class="mb-3">本活動屬機會中獎性質，依中華民國所得稅法及各類所得扣繳率標準規定:
                                <ul class="pl-4 mt-2">
                                    <li class="mb-2">中獎者若為中華民國境內居住之個人，凡單項中獎價值超過新臺幣1,000元含以上者，<span class="text-danger">需繳交身分證正反面影本</span>供報稅使用，年度報稅時將計入個人所得，次年初執行單位將依稅法相關規定辦理開立扣繳憑單。</li>
                                    <li class="mb-2">中獎者若<span class="text-danger">非中華民國境內居住之個人(即在中華民國境內居住未達183天之本國人及外國人)，不論中獎金額，須先就中獎所得扣繳20%機會中獎稅</span>後，始發予中獎獎項，活動承辦單位皆會開立扣繳憑單。</li>
                                    <li class="mb-2">中獎者若為<span class="text-danger">中華民國境內居住之個人且中獎獎項價值超過新臺幣20,000元含以上，中獎者須先負擔10%機會中獎所得稅。</span></li>
                                    <li class="mb-2">中獎者若為未滿20歲，視同父母或監護人中獎，由父母或監護人代為領獎。</li>
                                    <li class="mb-2">中獎者應提供核對身份相關資料(包含姓名、身份證件、通訊地址、手機號碼)。</li>
                                </ul>
                                以上任一若未能配合者，視同放棄中獎資格，且不得異議。中獎者參加本活動而需支付任何稅捐皆為中獎者之法定義務，概與主辦單位及活動承辦單位無關。前述稅捐法規如有更新或變動者，依修正後之規定辦理。
                            </li>
                            <li class="mb-3">領獎期限內未完成相關手續或領獎者，視同放棄得獎權利，不接受開立扣繳憑單者，亦同。</li>
                            <li class="mb-3">如因寄送過程中發生信件或包裹遺失、破損或其他不可歸責於主辦單位之原因，導致兌獎憑證遺失或無法辨識，或得獎者資料填寫不完整或不清楚，導致未能或不符資格兌獎，中獎者同意主辦單位無需為此負任何責任。</li>
                            <li class="mb-3">主、承辦單位保有最終修改、變更、活動解釋及取消本活動之權利，若有相關異動將會公告於網站，恕不另行通知。</li>
                            <li class="mb-3">本會保有取消、終止、修改或暫停本活動相關內容之權利，如有未盡事宜，悉依本會相關規定或解釋辦理，並得隨時補充公告之。相關規定或解釋辦理，並得隨時補充公告之。</li>
                        </ol>
                    </div>
                    <!-- bg -->
                    <img class="position-absolute intro__part3__sec3__bg" src="@/assets/images/intro/bg_intro_3_4.svg" alt="">
                </section>
                <!-- 裝飾img -->
                <img v-if="deviceMode != 'mobile'" class="position-absolute intro__part3__deco1" src="@/assets/images/intro/img_intro_2_deco6.png" alt="">
                <img v-if="deviceMode != 'mobile'" class="position-absolute intro__part3__deco2" src="@/assets/images/intro/img_intro_2_deco7.png" alt="">
                <img v-if="deviceMode != 'mobile'" class="position-absolute intro__part3__deco3" src="@/assets/images/intro/img_intro_2_deco9.png" alt="">
                <img v-if="deviceMode != 'mobile'" class="position-absolute intro__part3__deco4" src="@/assets/images/intro/img_intro_2_deco6.png" alt="">    
                <img v-if="deviceMode != 'mobile'" class="position-absolute intro__part3__deco5" src="@/assets/images/intro/img_intro_2_deco9.png" alt="">   
                <img v-if="deviceMode != 'mobile'" src="@/assets/images/intro/bg_intro_2_6.png" alt="" class="intro__part3__train--bottom w-100">
                <img v-else src="@/assets/images/intro/bg_intro_2_6_mb.png" alt="" class="intro__part3__train--bottom w-100">
            </div>

            <!-- 隱私權聲明 -->
            <div class="intro__privacy row justify-content-center" data-aos="fade-up" data-aos-duration="1500">
                <div class="col">
                    <section class="px-3 py-4 p-md-5">
                        <h2>隱私權聲明：</h2>
                        <p>感謝您參與「2021淡水八景4.0」（<a class="text-danger" href="https://www.2021tamsui.tw">https://www.2021tamsui.tw</a>）活動。為尊重並予以保護您的個人隱私權，請您詳細閱讀下列資訊：</p>
                        <ul class="list-unstyled mb-0">
                            <li class="mb-4">
                                <strong>政策適用範圍</strong><br>
                                以下的隱私權政策，適用於您在「2021淡水八景4.0」活動網站活動時，所涉及的個人資料蒐集、運用與保護。但不適用於與本網站功能連結之各政府機關或其他網站。凡經由「2021淡水八景4.0」（<a class="text-danger" href="https://www.2021tamsui.tw">https://www.2021tamsui.tw</a>）活動外部連結之網站，各網站均有其專屬之隱私權政策，本活動單位將不負任何連帶責任。</li>
                            <li id="sceneIntro_3" class="mb-4">
                                <strong>個資蒐集</strong>
                                <ol class="pl-4">
                                    <li>關於您在「2021淡水八景4.0」活動網站瀏覽網頁內容時，本網站並不會蒐集任何有關個人的身份資料。</li>
                                    <li>利用「2021淡水八景4.0」活動網站，所提供的各項服務或參與線上活動，本網站會依需求請您填寫資料，這些資料僅供活動抽獎之用途，不會將資訊用做其他用途。</li>
                                    <li>網站會記錄使用者上站的IP位址、上網時間以及在網站內所瀏覽的網頁行為等資料。這些資料係供「2021淡水八景4.0」活動網站，作為管理機關內部作為網站流量和網路行為調查的總量分析，以利於提昇本網站的服務品質，且「2021淡水八景4.0」活動網站僅對全體使用者行為總和進行分析，並不會對個別使用者進行分析。</li>
                                    <li> 「2021淡水八景4.0」活動網站有義務保護各申請人隱私，非經您本人同意不會自行修改或刪除任何個人資料及檔案。</li>
                                </ol>
                            </li>
                            <li class="mb-4">
                                <strong>共用政策</strong><br>「2021淡水八景4.0」絕不會任意提供出售、交換、或出租任何您的個人資料給其他團體、個人或私人企業。但有下列情形者除外：
                                <ol class="pl-4">
                                    <li>配合司法單位合法的調查。</li>
                                    <li>配合相關職權機關依職務需要之調查或使用。</li>
                                    <li>基於善意相信揭露為法律需要，或為維護和改進網站服務而用於管理。</li>
                                </ol>
                            </li>
                            <li>
                                <strong>活動詢問與申訴管道</strong><br>
                                若您在「2021淡水八景4.0」活動網站中有任何的疑問，可點選下方按鈕，填寫您的問題表單，我們將為您服務與解答。<br>
                                <router-link to="/contact" class="btn btn-danger mt-2">我要申訴</router-link>
                            </li>
                        </ul>
                    </section>
                </div>
            </div>
        </div>
    </div>
</template>


  
<script>
import $ from 'jquery';
import _ from "lodash"

let WH, playArea, getDiv, getDivWeight, trackRatio, trackPos_X1, trackPos_X2, turnPoint, turnPointTop, train_X, train_Y, trainHeight, trackPos_Y1, trackPos_Y2;

export default {
    data() {
        return {
            SceneList:[],
            defaultItem: '活動詳情',
            activeFilter: '',
        }
    },
    setup() {
        document.title = "2021淡水八景4.0-活動辦法";
        document.querySelector('meta[property="og:title"]').setAttribute("content", '2021淡水八景4.0-活動辦法');
        document.querySelector('meta[name="description"]').setAttribute("content", '淡水是結合多元文化與歷史古蹟的百年老鎮，美食、古蹟、絕景魅力爆表，趕緊收拾你的行李跟著我們，一起把淡水玩遍遍吧！');
        document.querySelector('meta[property="og:description"]').setAttribute("content", '淡水是結合多元文化與歷史古蹟的百年老鎮，美食、古蹟、絕景魅力爆表，趕緊收拾你的行李跟著我們，一起把淡水玩遍遍吧！');
        
    },
    created() {
        document.body.classList.remove("modal-open");
        window.addEventListener('scroll', this.handleScroll);
        window.addEventListener('click', this.handleClickaway);
        this.ReadData();
        if(this.$route.hash.split("#")[1] == 'part1'){
            this.defaultItem ='全民推薦大搜密';
        } else if(this.$route.hash.split("#")[1] == 'part2'){
            this.defaultItem ='絕景攝影邀請賽';
        } else if(this.$route.hash.split("#")[1] == 'part3'){
            this.defaultItem ='八景4.0全民票選抽大獎';
        } else if(this.$route.hash.split("#")[1] == 'part3_4'){
            this.defaultItem ='八景4.0全民票選抽大獎';
            setTimeout(() => {
				$('html, body').stop(true, false).animate({
                    scrollTop: $("#part3__sec2").offset().top - 120 
                }, 300);
			}, 200)            
        } else if(this.$route.hash.split("#")[1] == 'part3_5'){
            this.defaultItem ='八景4.0全民票選抽大獎';
            setTimeout(() => {
				$('html, body').stop(true, false).animate({
                    scrollTop: $("#part3__sec3").offset().top - 120 
                }, 300);
			}, 200)            
        }
    },
    beforeUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
        window.removeEventListener('click', this.handleClickaway);

    },
    mounted(){},
    updated(){
        if(this.$route.hash.split("#")[1] == 'part1'){
            this.defaultItem ='全民推薦大搜密';
        } else if(this.$route.hash.split("#")[1] == 'part2'){
            this.defaultItem ='絕景攝影邀請賽';
        } else if(this.$route.hash.split("#")[1] == 'part3'){
            this.defaultItem ='八景4.0全民票選抽大獎';
        }

        $(window).scroll(function(){
            // goTop
            if($(window).scrollTop()>=1000){
                $(".goTop").fadeIn(300).click(function() {
                    $('html, body').stop(true, false).animate({
                        scrollTop: 0
                    }, 'slow');
                    return false;
                });
            } else{
                $(".goTop").fadeOut(300);
            }
        });
    },
    methods: {
        showFilter: function(introClass){
            this.activeFilter = introClass;
        },
        ReadData:function(){
            var FD = new FormData();
            FD.append('mode', 'sct');
            FD.append('data', '{"class":"Scene","lang":"zh-TW"}');

            this.axios({
                method: 'post',
                url: 'RApi',
                data: FD,
                headers: {'Content-Type': 'multipart/form-data' }
            })
            .then(response => { 
                    this.SceneList=response.data.data.filter(item => ((item._Status_=='3'||item._Status_=='4') && item.TitleAlias!=''));        
            })
            .catch(function (error) {
                console.log(error);
            });
        }, 
        getNowPos: _.throttle((eventPart) => {
            var tunnel1='.intro__tunnel1';
            var tunnel2='.intro__tunnel2';
            var trainV='.intro__train--v';
            var trainH='.intro__train--h';
            
            var Ip1='.odd';
            var trainV1=$(Ip1).children(trainV);
            var trainH1=$(Ip1).children(trainH);

            var Ip2='.even'; 
            var trainV2=$(Ip2).children(trainV);
            var trainH2=$(Ip2).children(trainH);

            playArea = $(window).height()*0.65; //動畫執行區域的高度
            getDiv = '.intro__part0'; //要計算的外層容器
            getDivWeight = $(getDiv).width() - 30;
            trackRatio = 0.83;
            trackPos_X1 = getDivWeight*(1-trackRatio)/2;
            trackPos_X2 = getDivWeight*trackRatio-trackPos_X1;

            WH = parseInt(window.scrollY)+1; //卷軸高
            trainHeight = $(trainV).height();
            
            if(eventPart =='全民推薦大搜密' || eventPart =='八景4.0全民票選抽大獎' ){
                if(($(Ip1).offset().top-WH) < playArea){      
                    trackPos_Y1 = parseInt($(Ip1).children(tunnel1).position().top+(trainHeight/2));
                    trackPos_Y2 = parseInt($(Ip1).children(tunnel2).position().top-(trainHeight/2));          

                    // X軸(X1>>>X2)
                    if(parseInt($(Ip1).children(tunnel1).offset().top-WH) < playArea){

                        turnPoint = $(Ip1).children(tunnel1); //隧道(轉彎處)
                        turnPointTop = parseInt(turnPoint.offset().top-WH); //隧道(轉彎處)跟視窗Top距離
                        train_X = trackPos_X2-(turnPointTop/(playArea/(trackPos_X2-trackPos_X1))); //動態X軸座標
                        train_Y = $(turnPoint).position().top + ($(turnPoint).height()/3.5); //動態Y軸座標

                        if(train_X < trackPos_X2){
                            trainH1.css({ opacity: '1', top: train_Y+'px', left: train_X+'px'}); 
                            trainV1.css({ opacity: '0', top: trackPos_Y1+'px'});
                        } else {
                            trainH1.css({ opacity: '0', top: train_Y+'px', left: trackPos_X2+'px'}); 
                        }
                    } 
                    
                    // X軸(X2>>>X1)
                    if(parseInt($(Ip1).children(tunnel2).offset().top-WH) < playArea){
                                            
                        turnPoint = $(Ip1).children(tunnel2); 
                        turnPointTop = parseInt(turnPoint.offset().top-WH);
                        train_X = trackPos_X1+(turnPointTop/(playArea/(trackPos_X2-trackPos_X1)));                    
                        train_Y = $(turnPoint).position().top + ($(turnPoint).height()/3.5);

                        if(train_X > trackPos_X1){
                            trainH1.css({ opacity: '1', top: train_Y+'px', left: train_X+'px'}); 
                            trainV1.css({ opacity: '0', top: trackPos_Y2+'px'});
                        } else {
                            trainH1.css({ opacity: '0', top: train_Y+'px', left: trackPos_X1+'px'}); 
                        }
                    } 
                    
                    // Y軸
                    if(parseInt($(Ip1).children(tunnel1).offset().top-WH) <= 0){     
                        turnPoint = $(Ip1).children(tunnel1); 
                        turnPointTop = parseInt(turnPoint.offset().top-WH); 
                        train_X = trackPos_X2-(turnPointTop/(playArea/(trackPos_X2-trackPos_X1))); 
                        train_Y = $(window).scrollTop() - 300;

                        if(train_Y < trackPos_Y2){
                            trainV1.css({ opacity: '1', top: train_Y+'px'});
                            trainH1.css({ opacity: '0', left: trackPos_X2+'px'}); 
                        } else {
                            trainV1.css({ opacity: '0', top: trackPos_Y2+'px'});
                        }    
                    } else {trainV1.css({ opacity: '0', top: trackPos_Y1+'px'});}
                }
            } else if(eventPart == '絕景攝影邀請賽'){
                if(($(Ip2).offset().top-WH) < playArea){
                    trackPos_Y1 = parseInt($(Ip2).children(tunnel1).position().top+(trainHeight/2));
                    trackPos_Y2 = parseInt($(Ip2).children(tunnel2).position().top-(trainHeight/2));

                    // X軸(X2>>>X1)
                    if(parseInt($(Ip2).children(tunnel1).offset().top-WH) < playArea){                                            
                        turnPoint = $(Ip2).children(tunnel1); 
                        turnPointTop = parseInt(turnPoint.offset().top-WH);
                        train_X = trackPos_X1+(turnPointTop/(playArea/(trackPos_X2-trackPos_X1)));                    
                        train_Y = $(turnPoint).position().top + ($(turnPoint).height()/3.5);

                        if(train_X > trackPos_X1){
                            trainH2.css({ opacity: '1', top: train_Y+'px', left: train_X+'px'}); 
                            trainV2.css({ opacity: '0', top: trackPos_Y2+'px'});
                        } else {
                            trainH2.css({ opacity: '0', top: train_Y+'px', left: trackPos_X1+'px'}); 
                        }
                    } 

                    // Y軸
                    if(parseInt($(Ip2).children(tunnel1).offset().top-WH) <= 0){     
                        turnPoint = $(Ip2).children(tunnel1); 
                        turnPointTop = parseInt(turnPoint.offset().top-WH); 
                        train_X = trackPos_X2-(turnPointTop/(playArea/(trackPos_X2-trackPos_X1)));  
                        train_Y = $(window).scrollTop() - 300;
                        // train_Y = trackPos_Y1+Math.abs(turnPointTop)*1.13;
                        
                        if(train_Y < trackPos_Y2){
                            trainV2.css({ opacity: '1', top: train_Y+'px'});
                            trainH2.css({ opacity: '0', left: trackPos_X1+'px'}); 
                        } else {
                            trainV2.css({ opacity: '0', top: trackPos_Y2+'px'});
                        }        
                    } else {trainV2.css({ opacity: '0', top: trackPos_Y1+'px'});}

                    // X軸(X1>>>X2)
                    if(parseInt($(Ip2).children(tunnel2).offset().top-WH) < playArea){
                        turnPoint = $(Ip2).children(tunnel2); 
                        turnPointTop = parseInt(turnPoint.offset().top-WH); 
                        train_X = trackPos_X2-(turnPointTop/(playArea/(trackPos_X2-trackPos_X1)));               
                        train_Y = $(turnPoint).position().top + ($(turnPoint).height()/3.5);
                        
                        if(train_X < trackPos_X2){
                            trainH2.css({ opacity: '1', top: train_Y+'px', left: train_X+'px'}); 
                            trainV2.css({ opacity: '0', top: trackPos_Y2+'px'});
                        } else {
                            trainH2.css({ opacity: '1', top: train_Y+'px', left: trackPos_X2+'px'}); 
                        }   
                    } 
                }
            }
        }, 500),

        handleScroll() {
            this.getNowPos(this.defaultItem)
        },
        
        handleClickaway(e) {
			const { clickaway } = this.$refs;
			if(!clickaway.contains(e.target)){
				this.activeFilter = '';
			}
        },

        scrollToEvent(){
            $('html, body').stop(true, false).animate({
                scrollTop: $(".subNav").offset().top - 20
            }, 0);
        }
    },
}
</script>